import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import Router from "./Router";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClientProvider, QueryClient } from "react-query";
import { BounceLoader } from "react-spinners";
import "./styles/reset.scss";
import "./styles/common.scss";
import "../src/index.css";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary fallback={<div>ErrorBoundary 컴포넌트! Error!</div>}>
    <Suspense fallback={<BounceLoader color="#36d7b7" speedMultiplier={3} />}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </Suspense>
  </ErrorBoundary>
);
