import React from "react";
import styled from "styled-components";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => <Container>{children}</Container>;

export default Layout;

const Container = styled.div`
  padding: 50px 30px 0px 30px;
  width: 100vw;
  height: 100vh;
`;
