import { createColumnNames } from "./createColumnNames";
import { Worksheet } from "exceljs";

import type { ExcelDataItem } from "../axiosPost";

export const setColumnHeader = (
  worksheet: Worksheet,
  excelData: ExcelDataItem[] | undefined
) => {
  if (!excelData) return;
  const columns = createColumnNames(excelData).map(columnName => ({
    header: columnName,
    key: columnName,
    width: columnName.length + 10,
  }));

  worksheet.columns = columns;
};
