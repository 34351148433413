import React, { useMemo } from "react";
import EChartsReact from "echarts-for-react";

import { useReportsQuery } from "../../../hooks/eren/useReportsQuery";
import { getOption } from "../../../utils/eren/echarts/getOption";
import { sortData } from "../../../utils/eren/echarts/sortData";

interface SaleProps {
  company: string;
}

const Sale = ({ company }: SaleProps) => {
  const { data } = useReportsQuery();
  const reports = useMemo(() => sortData(data, company), [data, company]);

  return (
    <>
      <h1>매출</h1>
      <EChartsReact option={getOption(reports)} />
    </>
  );
};

export default Sale;
