import { getDates } from "./getDates";
import { getSeries } from "./getSeries";

import type { Report } from "./../../../hooks/eren/useReportsQuery";

export const getOption = (reports: Report[]) => {
  const series = reports[0].data[1].data;

  return {
    legend: {
      orient: "horizontal",
      top: "bottom",
    },
    tooltip: {
      trigger: "axis",
    },
    xAxis: [
      {
        type: "category",
        data: getDates(reports, "YYYY년 MM월"),
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: getSeries(reports, series),
  };
};
