import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Main from "./pages/Main/Main";
import Layout from "./components/Layout/Layout";
import Modal from "./components/Modal";

const Router = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route
            path="/trendreport/SAMPLE/:companyName"
            element={<Dashboard />}
          />
        </Routes>
        <Modal title="Excel Download" buttonValue="확인" />
      </Layout>
    </BrowserRouter>
  );
};

export default Router;
