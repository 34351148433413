import type { Report, SubData } from "../../../hooks/eren/useReportsQuery";

const isSubData = (data: string | SubData): data is SubData => {
  return typeof data !== "string";
};

export const getValues = (reports: Report[], graphName: string) => {
  const [salesGraph, flowOfFundsGraph]: ["1", "2"] = ["1", "2"];

  const index = ["매출", "순이익"].includes(graphName)
    ? salesGraph
    : flowOfFundsGraph;

  return reports.map(report => {
    const graph = report.data[index];

    if (isSubData(graph.data)) {
      return graph.data[graphName].value;
    } else {
      throw new Error("Invalid data type");
    }
  });
};
