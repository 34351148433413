import type { ExcelDataItem } from "../axiosPost";

export const createRows = (excelData: ExcelDataItem[]) => {
  // console.log("excelData :", excelData);

  return excelData
    .map((data, idx) => {
      // console.log("idx :", idx);
      // console.log("data :", data);

      const {
        large_category,
        middle_category,
        keyword_id,
        keyword,
        priority,
        context,
        variation_info,
      } = data;

      // console.log("variation_info :", variation_info);
      // console.log("variation_info.length :", variation_info.length);

      if (variation_info.length === 0) {
        return [
          [
            large_category,
            middle_category,
            keyword_id,
            null,
            keyword,
            null,
            null,
            priority ?? null,
            context,
          ],
        ];
      } else {
        return variation_info.map(([negativePrompt, keywordList]) => [
          large_category,
          middle_category,
          keyword_id,
          null,
          keyword,
          keywordList || null,
          negativePrompt ? "Y" : null,
          priority ?? null,
          context,
        ]);
      }
    })
    .flat();
};
