import React from "react";
import { useParams } from "react-router-dom";
import Sale from "./components/Sale";

import "./Dashboard.scss";

const Dashboard = () => {
  const { companyName } = useParams();

  if (companyName === undefined) {
    throw new Error("No company");
  }

  return (
    <>
      <h1>{companyName} Dashboard</h1>
      <Sale company={companyName} />
    </>
  );
};

export default Dashboard;
