import { getValues } from "./getValues";

import type { Report, SubData } from "../../../hooks/eren/useReportsQuery";

export const getSeries = (reports: Report[], series: SubData) => {
  const graphItems = Object.entries(series);

  return graphItems.map((ele, idx) => {
    const [graphName, graphData] = ele;

    return {
      name: graphName,
      data: getValues(reports, graphName),
      type: graphData.chart,
      color: reports[0].color[idx],
    };
  });
};
