import ExcelJS from "exceljs";
import { setColumnHeader } from "./setColumnHeader";
import { createRows } from "./createRows";
import { activateRow } from "./activateRow";
import { unlockCells } from "./unlockCells";
import { setColumnType } from "./setColumnType";
import { ExcelDataItem } from "../axiosPost";

const createExcel = async (excelData: ExcelDataItem[]) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("My Sheet");

  setColumnHeader(worksheet, excelData);

  const rows = createRows(excelData);
  worksheet.addRows(rows);
  activateRow(worksheet, 20);
  unlockCells(worksheet);
  setColumnType(worksheet, "Priority", "whole");
  worksheet.getRow(1).font = { bold: true };
  worksheet.columns.forEach(column => {
    if (column.header === "CONTEXT") column.alignment = { horizontal: "left" };
    else column.alignment = { horizontal: "center" };
  });

  return await workbook.xlsx.writeBuffer();
};

export default createExcel;
