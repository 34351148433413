import axios from "axios";
import { useQuery } from "react-query";

import { MOCK_URLS_MAP } from "../../constants/constants";

type GraphType = "mixed" | "text";

export interface BaseData {
  value: string;
  chart: string;
}

export interface SubData {
  [key: string]: BaseData;
}

interface ReportData<T extends GraphType> {
  label: string;
  type: T;
  data: T extends "mixed" ? SubData : string;
}

export interface Report {
  id: string;
  color: string[];
  date: string;
  data: {
    [K in "1" | "2" | "3" | "4" | "5"]: ReportData<
      K extends "1" | "2" ? "mixed" : "text"
    >;
  };
}

export const useReportsQuery = () => {
  const fetchReports = async () => {
    const url = MOCK_URLS_MAP.get("reports") ?? "";
    const data = (await axios<Report[]>(url)).data;

    return data;
  };

  return useQuery(["reports"], fetchReports, {
    suspense: true,
    useErrorBoundary: true,
  });
};
