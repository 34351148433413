import React from "react";
import { FadeLoader } from "react-spinners";
import Button from "@mui/material/Button";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import useModalStore from "../store/eren/modalStore";
import useLoadingStore from "../store/eren/loadingStore";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1d3952",
    },
  },
});
interface ModalProps {
  title: string;
  buttonValue: string;
}

const Modal = ({ title, buttonValue }: ModalProps) => {
  const isModalOpen = useModalStore(state => state.isModalOpen);
  const isLoading = useLoadingStore(state => state.isLoading);
  const loadingMessage = useLoadingStore(state => state.loadingMessage);

  const setModalOpen = useModalStore(state => state.setModalOpen);
  const setLoadingMessage = useLoadingStore(state => state.setLoadingMessage);

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoadingMessage("");
  };

  return (
    <div
      className={`${
        isModalOpen ? "" : "hidden"
      } fixed inset-0 z-10 overflow-y-auto`}
    >
      <div className="flex items-center justify-center min-h-screen">
        <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-50" />
        <div className="relative z-20 p-4 bg-white rounded-md animate-in fade-in w-96 h-44">
          <div className="flex flex-col items-center h-full">
            <div className="header grow">{title}</div>
            <div className="flex-auto pl-4">
              {isLoading ? (
                <FadeLoader
                  color="#5f6c6a"
                  cssOverride={{}}
                  height={13}
                  loading={true}
                  margin={-2}
                  radius={13}
                  speedMultiplier={1.3}
                  width={4}
                />
              ) : (
                <div>{loadingMessage}</div>
              )}
            </div>
            <div className="footer grow-1">
              <ThemeProvider theme={theme}>
                <Button
                  onClick={handleCloseModal}
                  variant="outlined"
                  color="primary"
                  disabled={isLoading}
                >
                  {buttonValue}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
