import axios from "axios";
import useLoadingStore from "../../store/eren/loadingStore";

export interface ExcelDataItem {
  large_category: string | null;
  middle_category: string | null;
  keyword_id: string | null;
  keyword: string;
  priority: number;
  variation_info: [boolean, string][];
  context: string;
}

export const axiosPost = async (url: string, formData?: FormData) => {
  const { setLoadingMessage, setLoadingActive } = useLoadingStore.getState();

  try {
    const excelData: ExcelDataItem[] = (await axios.post(`${url}`, formData))
      .data.result;
    setLoadingMessage("Excel Download Successful");

    return excelData;
  } catch (err) {
    setLoadingActive(false);
    if (err instanceof Error) {
      setLoadingMessage(err.message);
    }
  }
};
