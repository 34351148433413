interface ColumnMap {
  [key: string]: string[] | string;
}

type AdditionalColumns = [{ name: "추가"; idx: 3 }];

type AnalysisUrl = "https://ethno1.earlysloth.com/analysis/keyword/extract/";

export const MOCK_URLS_MAP = new Map<"reports" | "excel", string>([
  ["reports", "/data/eren/reports.json"],
  ["excel", "/data/eren/excel.json"],
]);

export const COLUMN_MAP: ColumnMap = {
  large_category: "대분류",
  middle_category: "중분류",
  keyword_id: "키워드 ID",
  keyword: "키워드",
  variation_info: ["Negative Prompt", "키워드 목록"],
  priority: "Priority",
  context: "CONTEXT",
};

export const ADDITIONAL_COLUMNS: AdditionalColumns = [{ name: "추가", idx: 3 }];

export const LOCKED_COLUMNS: ReadonlyArray<string> = [
  "대분류",
  "중분류",
  "키워드 ID",
];

export const ANALYSIS_URL: AnalysisUrl =
  "https://ethno1.earlysloth.com/analysis/keyword/extract/";
