import React, { useState } from "react";
import { ANALYSIS_URL } from "../../../constants/constants";
import { axiosPost } from "../../../utils/eren/axiosPost";
import useLoadingStore from "../../../store/eren/loadingStore";
import useModalStore from "../../../store/eren/modalStore";
import { saveAs } from "file-saver";
import createExcel from "../../../utils/eren/exceljs/createExcel";
import styled from "styled-components";

const ExcelUpload = () => {
  const handleUploadExcel = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { setModalOpen } = useModalStore.getState();
    const { setLoadingMessage, setLoadingActive } = useLoadingStore.getState();

    setModalOpen(true);
    setLoadingActive(true);

    const formData = new FormData(e.currentTarget);

    const excelData = await axiosPost(ANALYSIS_URL, formData);

    if (excelData === undefined) {
      setLoadingActive(false);
      setLoadingMessage("excelData is undefined");

      return;
    }

    const buffer = await createExcel(excelData);
    saveAs(new Blob([buffer]), `My_Sheet_${Date.now()}.xlsx`);

    setLoadingActive(false);
  };

  const [fileName, setFileName] = useState("첨부파일");

  return (
    <form id="formElem" onSubmit={handleUploadExcel}>
      <Path value={fileName} placeholder={fileName} readOnly />
      <Label htmlFor="file">파일찾기</Label>
      <InputFile
        id="file"
        type="file"
        name="file"
        accept=".xlsx"
        onChange={e => setFileName(e.target.value)}
      />
      <InputSubmit
        type="submit"
        value="파일 업로드"
        disabled={fileName !== "첨부파일" ? false : true}
      />
    </form>
  );
};

export default ExcelUpload;

const Path = styled.input`
  display: inline-block;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  border: 1px solid #dddddd;
  width: 300px;
  color: #999999;
`;

const Label = styled.label`
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  vertical-align: middle;
  background-color: #999999;
  cursor: pointer;
  height: 40px;
  margin-left: 10px;
`;

const InputFile = styled.input`
  position: absolute;
  width: 0;
  height: 0;
  padding: 0;
  overflow: hidden;
  border: 0;
`;

const InputSubmit = styled.input`
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  vertical-align: middle;
  background-color: #999999;
  cursor: pointer;
  height: 40px;
  margin-left: 10px;

  :disabled {
    cursor: not-allowed;
    color: #a9a2a2;
    background-color: #6a6a6a;
  }
`;
