import { Worksheet } from "exceljs";

export const setColumnType = (
  worksheet: Worksheet,
  columnName: string,
  type: string
) => {
  const column = worksheet.getColumn(columnName);

  switch (type) {
    case "whole":
      column.eachCell({ includeEmpty: true }, (cell, cellNum) => {
        if (cellNum !== 1)
          cell.dataValidation = {
            type: type,
            allowBlank: true,
            showErrorMessage: true,
            error: "숫자만 입력가능 합니다.",
            errorTitle: "잘못된 입력",
            formulae: [],
          };
      });
      break;
    default:
      return;
  }
};
