import { Worksheet, Cell } from "exceljs";
import { LOCKED_COLUMNS } from "../../../constants/constants";

export const unlockCells = (worksheet: Worksheet) => {
  const columns = worksheet.columns;
  if (!columns) return;

  columns.forEach(column => {
    if (column.eachCell) {
      column.eachCell({ includeEmpty: true }, (cell, cellNum) => {
        unlockCell(cell, cellNum, isLockedColumn(column.key));
      });
    }
  });
};

const isLockedColumn = (key: string | undefined) =>
  key !== undefined && LOCKED_COLUMNS.includes(key);

const unlockCell = (cell: Cell, cellNum: number, isLockedColumn: boolean) => {
  const isNotEmptyCell = !!cell.value;
  const isNotHeader = cellNum !== 1 ? true : false;

  if (isNotHeader && shouldUnlockCell(isLockedColumn, isNotEmptyCell))
    cell.protection = { locked: false };
};

const shouldUnlockCell = (isLockedColumn: boolean, isNotEmptyCell: boolean) => {
  return !(isLockedColumn && isNotEmptyCell);
};
