import { create } from "zustand";

interface ModalStore {
  isModalOpen: boolean;
  setModalOpen: (isOpen: boolean) => void;
}

const useModalStore = create<ModalStore>(set => ({
  isModalOpen: false,
  setModalOpen: isOpen => set(() => ({ isModalOpen: isOpen })),
}));

export default useModalStore;
