import dayjs from "dayjs";

import type { Report } from "../../../hooks/eren/useReportsQuery";

export const getDates = (reports: Report[], format = "YYYY년 MM월") => {
  return reports.map(report => {
    const unixTimestamp = parseInt(report.date);
    return dayjs.unix(unixTimestamp).format(format);
  });
};
