import { ADDITIONAL_COLUMNS, COLUMN_MAP } from "../../../constants/constants";
import { ExcelDataItem } from "../axiosPost";

export const createColumnNames = (excelData: ExcelDataItem[]) => {
  const [
    large_category,
    middle_category,
    keyword_id,
    keyword,
    priority,
    negativePrompt,
    keywordList,
    context,
  ] = Object.keys(excelData[0])
    .map(key => COLUMN_MAP[key])
    .flat();

  const columnNames = [
    large_category,
    middle_category,
    keyword_id,
    keyword,
    keywordList,
    negativePrompt,
    priority,
    context,
  ];

  ADDITIONAL_COLUMNS.forEach(column =>
    columnNames.splice(column.idx, 0, column.name)
  );

  return columnNames;
};
