import { create } from "zustand";

interface LoadingStore {
  isLoading: boolean;
  loadingMessage: string;

  setLoadingMessage: (text: string) => void;
  setLoadingActive: (isActive: boolean) => void;
}

const useLoadingStore = create<LoadingStore>(set => ({
  isLoading: false,
  loadingMessage: "",

  setLoadingMessage: text => set(() => ({ loadingMessage: text })),
  setLoadingActive: isActive => set(() => ({ isLoading: isActive })),
}));

export default useLoadingStore;
